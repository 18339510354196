import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PrizesDate = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [prizes, setPrizes] = useState([]);
  const navigate = useNavigate();

  const handleSearch = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`https://3008455-bj43204.twc1.net/api/wheel/logs?start=${startDate}&end=${endDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const filteredPrizes = response.data.filter((log) => {
        const logDate = new Date(log.date);
        const from = new Date(startDate);
        const to = new Date(endDate);
        return logDate >= from && logDate <= to;
      });

      const prizeSummary = filteredPrizes.reduce((acc, log) => {
        if (log.prize) {
          acc[log.prize] = (acc[log.prize] || 0) + 1;
        }
        return acc;
      }, {});

      setPrizes(Object.entries(prizeSummary).map(([prize, count]) => ({ prize, count })));
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <h1 className="text-2xl mb-6 text-blue-500 font-bold text-center">Поиск выигрышей по дате</h1>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-gray-700 font-bold mb-2">Дата с:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">Дата до:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <button
          onClick={handleSearch}
          className="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
        >
          Найти
        </button>

        <div className="mt-8">
          {prizes.length > 0 ? (
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-3 text-left">Приз</th>
                  <th className="border border-gray-300 p-3 text-left">Количество</th>
                </tr>
              </thead>
              <tbody>
                {prizes.map((prize, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="border border-gray-300 p-3">{prize.prize}</td>
                    <td className="border border-gray-300 p-3">{prize.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-500 mt-4">Призы не найдены.</p>
          )}
        </div>

        <button
          onClick={() => navigate(-1)}
          className="mt-6 w-full py-3 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
        >
          Назад
        </button>
      </div>
    </div>
  );
};

export default PrizesDate;

import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function GenerateCode() {
  const [orderAmount, setOrderAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const handleGenerate = async () => {
    try {
      const res = await axios.post('https://3008455-bj43204.twc1.net/api/code/generate-code', { orderAmount, phoneNumber });
      setCode(res.data);
      toast.success('Код успешно сгенерирован!');
    } catch (err) {
      toast.error('Не удалось сгенерировать код');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-3xl mb-6 text-blue-500 font-bold text-center">Генерация кода</h1>
        <input
          type="number"
          value={orderAmount}
          onChange={(e) => setOrderAmount(e.target.value)}
          placeholder="Сумма заказа в рублях"
          className="mb-4 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
        />
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Номер телефона"
          className="mb-4 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
        />
        <button
          onClick={handleGenerate}
          className="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
        >
          Сгенерировать код
        </button>
        {code && (
          <div className="mt-4 text-center">
            <div className="text-lg mb-2">Сгенерированный код: {code}</div>
            <button
              onClick={() => navigator.clipboard.writeText(code)}
              className="w-full py-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-200"
            >
              Скопировать код
            </button>
          </div>
        )}
        <button
          onClick={() => navigate(-1)}
          className="mt-6 w-full py-3 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
        >
          Назад
        </button>
      </div>
    </div>
  );
}

export default GenerateCode;
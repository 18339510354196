import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from './helpers/AuthContext';


function AdminLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {setAuth} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post('https://3008455-bj43204.twc1.net/api/auth/login', { username, password });
      localStorage.setItem('token', res.data.token);
      if ( res.data.username === 'superadmin') {
        setAuth({
          username: res.data.username,
          id: res.data.id,
          status: true,
          issuperadmin: true
        });
      } else {
        setAuth({
          username: res.data.username,
          id: res.data.id,
          status: true,
          issuperadmin: false
        });
      }
      toast.success('Login successful!');
      navigate('/admin/panel');
    } catch (err) {
      toast.error('Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r">
      {isLoading ? (
        <div className="text-2xl text-blue-600 animate-pulse">Загрузка...</div>
      ) : (
        <form onSubmit={handleSubmit} className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg space-y-4">
          <h1 className="text-3xl mb-4 text-blue-500 font-bold">Вход</h1>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Логин"
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Пароль"
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
          />
          <button
            type="submit"
            className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Войти
          </button>
        </form>
      )}
    </div>
  );
}

export default AdminLogin;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function ManagePrizes() {
  const [prizes, setPrizes] = useState([]);
  const [name, setName] = useState('');
  const [probability, setProbability] = useState('');
  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrizes = async () => {
      const token = localStorage.getItem('token');
      try {
        const res = await axios.get('https://3008455-bj43204.twc1.net/api/wheel/prizes', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setPrizes(res.data);
      } catch (err) {
        toast.error('Не удалось загрузить призы');
      }
    };
    fetchPrizes();
  }, []);

  const handleAddPrize = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('probability', probability);
    if (file) {
      formData.append('image', file);
    }

    try {
      await axios.post('https://3008455-bj43204.twc1.net/api/wheel/prize', formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
      });
      toast.success('Приз успешно добавлен!');
      setName('');
      setProbability('');
      setFile(null);
      const res = await axios.get('https://3008455-bj43204.twc1.net/api/wheel/prizes', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPrizes(res.data);
    } catch (err) {
      toast.error('Не удалось добавить приз');
    }
  };

  const handleAddLink = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post('https://3008455-bj43204.twc1.net/api/wheel/link', {link}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLink('');
      toast.success('Ссылка успешно добавлена')
    } catch (err) {
      toast.error('Не удалось добавить ссылку')
    }
  }
  

  const handleDeletePrize = async (id) => {
    // console.log('Deleting prize with ID:', id); // Проверьте значение ID
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`https://3008455-bj43204.twc1.net/api/wheel/prize/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Приз успешно удален!');
      setPrizes(prizes.filter(prize => prize.id !== id));
    } catch (err) {
      console.error('Error deleting prize:', err); // Проверьте ошибку
      toast.error('Не удалось удалить приз');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r p-8">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-3xl mb-6 text-blue-500 font-bold text-center">Управление призами</h1>
        <div className="flex flex-col items-center mb-6 space-y-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Название приза"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
          />
          <input
            type="file"
            id="fileInput"
            onChange={(e) => setFile(e.target.files[0])}
            className="hidden"
          />
          <button
            onClick={() => document.getElementById('fileInput').click()}
            className="w-full p-3 border border-gray-300 rounded-md bg-blue-500 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          >
            Добавить фото
          </button>

          <input
            type="number"
            value={probability}
            onChange={(e) => setProbability(e.target.value)}
            placeholder="Вероятность (%)"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
          />
          <button
            onClick={handleAddPrize}
            className="w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Добавить Приз
          </button>
        <div className='py-6'>
          <h3 className='text-3xl mb-6 text-blue-500 font-bold text-center'>Добавить ссылку на список призов</h3>
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Ссылка"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
          />
          <button
            onClick={handleAddLink}
            className="w-full py-3 my-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Добавить Ссылку
          </button>
        </div>
        </div>
        <ul className="w-full max-w-md mx-auto">
          {prizes.map(prize => (
            <li key={prize._id} className="flex justify-between items-center mb-4 p-4 border border-gray-300 rounded-md bg-white shadow-md">
              <div className="flex flex-col w-40 items-center">
                <div className="font-bold mb-2 text-center` ">{prize.name}</div>
                <img src={`https://3008455-bj43204.twc1.net/uploads/${prize.image}`} alt={prize.name} className="w-24 h-24 object-cover mb-2" />
                <div>Вероятность: {prize.probability}%</div>
              </div>
              <button
                onClick={() => handleDeletePrize(prize.id)}
                className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-200"
              >
                Удалить
              </button>
            </li>
          ))}
        </ul>
        <button
          onClick={() => navigate(-1)}
          className="mt-6 w-full py-3 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
        >
          Назад
        </button>
      </div>
    </div>
  );
}

export default ManagePrizes;

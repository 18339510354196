import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './helpers/AuthContext';

function AdminPanel() {
  const { auth } = useContext(AuthContext);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r p-8">
      <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h1 className="text-3xl text-blue-500 font-bold mb-6 text-center">
          Панель Администратора
        </h1>
        <div className="flex flex-col md:flex-row md:justify-center space-y-4 md:space-y-0 md:space-x-6">
          <Link
            to="/admin/generate-code"
            className="w-full md:w-auto px-6 py-3 bg-blue-500 text-white rounded-md text-center hover:bg-blue-600 transition duration-200"
          >
            Генерация кода
          </Link>

          {auth.issuperadmin ? (
            <Link
              to="/admin/manage-prizes"
              className="w-full md:w-auto px-6 py-3 bg-blue-500 text-white rounded-md text-center hover:bg-blue-600 transition duration-200"
            >
              Управление призами
            </Link>
          ) : null}
          

          <Link
            to="/admin/logs"
            className="w-full md:w-auto px-6 py-3 bg-blue-500 text-white rounded-md text-center hover:bg-blue-600 transition duration-200"
          >
            Журнал выигрышей
          </Link>

          {auth.issuperadmin ? (
            <Link
              to="/admin/prizes-date"
              className="w-full md:w-auto px-6 py-3 bg-blue-500 text-white rounded-md text-center hover:bg-blue-600 transition duration-200"
            >
            Отчеты призов
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;

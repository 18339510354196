import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Logs() {
  const [logs, setLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLogs = async () => {
      const token = localStorage.getItem('token');
      try {
        const res = await axios.get('https://3008455-bj43204.twc1.net/api/wheel/logs', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const reversedLogs = res.data.reverse();
        setLogs(reversedLogs);
      } catch (err) {
        toast.error('Не удалось загрузить логи');
      }
    };
    fetchLogs();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const mask = (phoneNumber, number) => {
    if (!phoneNumber) return '';
    const phoneStr = phoneNumber.toString();
    const maskedSection = phoneStr.slice(0, number).replace(/./g, '*');
    const visibleSection = phoneStr.slice(number);
    return `${maskedSection}${visibleSection}`;
  };

  const filteredLogs = logs.filter(log =>
    log.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r p-8">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h1 className="text-3xl mb-6 text-blue-500 font-bold text-center">Журнал выигрышей</h1>
        <input
          type="search"
          placeholder="Поиск по номеру телефона"
          value={searchQuery}
          onChange={handleSearchChange}
          className="mb-6 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <ul className="space-y-4">
          {filteredLogs.map(log => (
            <li key={log.id} className="p-4 border border-gray-300 rounded-md bg-white shadow-sm">
              <div className="space-y-2">
                {log.username ? (
                  <div className="font-bold">Пользователь: {log.username}</div>
                ) : (
                  <div className="font-bold">Код</div>
                )}
                <p>Номер телефона: <span>{log.phoneNumber}</span></p>
                {log.username ? (
                  <div>Код: {log.code}</div>
                ) : (
                  <div>Код: {mask(log.code, -4)}</div>
                )}
                <div>Сумма заказа: {log.orderAmount} руб.</div>
                {log.prize ? (
                  <div>Приз: {log.prize}</div>
                ) : null}
                {log.prizeImage && (
                  <img
                    src={`https://3008455-bj43204.twc1.net/uploads/${log.prizeImage}`}
                    alt={log.prize}
                    className="w-24 h-24 object-cover mt-2"
                  />
                )}
                {log.date && (
                  <div>Дата и время выигрыша: {new Date(log.date).toLocaleString()}</div>
                )}
                <div>Дата и время регистрации кода: {new Date(log.dateCode).toLocaleString()}</div>
              </div>
            </li>
          ))}
        </ul>
        <button
          onClick={() => navigate(-1)}
          className="mt-6 w-full py-3 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
        >
          Назад
        </button>
      </div>
    </div>
  );
}

export default Logs;
